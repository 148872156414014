<script>
// import Withdrawal from "../../components/Withdrawal/Withdrawal.vue";
// import Addbank from "../../components/AddBank/AddBank.vue";
import payment from "../../common/json/payment.json";
import { user, indexdashboard, info, bankcard, wallet } from "../../api/index.js";
import config from "@/api/config.js";
export default {
  data() {
    return {
      mainloading: true, //折线图是否显示
      nameloading: true, //账户信息是否显示
      listloading: true, //公告是否显示
      language: "", //语言
      pid: 0,
      second_box1_change: 0, //判断是日账单还是跳转充值明细
      money: this.$store.state.money, //马币
      thb_money: "", //泰币
      usdt_money: "", //usdt
      num: 300000, //近一周交易笔数
      gameName: "", //商户名
      id: "",
      mail: "", //账户名
      money2: "",
      newlist: [], //公告列表
      busy: false,
      page: 1, //第多少页
      limit: 4, //每页好多条
      pages: 1, //共多少页
      dialogVisible: false, //展示公告
      noticcontent: "", //公告名
      notictitle: "", //公告类容
      timeStr: "week", //统计类型
      Withdrawaldiv: false, //是否展示提现按钮
      options2: [
        //币种
      ],
      Currency: "MYR", //币种筛选
      inr_money: "", //印度币
      wanbi_money: "", //wanbi
      gobi_money: "", //gobi
      rmb_money: "", //人民币
      Todaysamount: "",
      yesterdayamount: "",
      monthamount: "",
      dayWith: "", //今日提现
      rechargeTotal: "", //充值总额
      Account_balanceshow: false,
      addtrue: {
        addtrue: false,
        currency: "",
      },
      noSettledMoney: "",
      Withdrawaltrue: {
        Withdrawaltrue: false,
        currency: "",
      },
    };
  },
  components: {
    // Withdrawal,
    // Addbank,
  },
  created() {
    this.options2 = payment.otherCurrencylist;
    this.changelanguage();
    localStorage.setItem("showurl", "/");
  },
  watch: {
    // 语言变化切换语言
    "$store.state.language"() {
      this.changelanguage();
    },
    // 跳转日账单还是明细
    second_box1_change() {
      if (this.second_box1_change == 0) {
        // this.timeStr = "today"
        this.$router.push("/transactionCenter");
      } else if (this.second_box1_change == 1) {
        // this.timeStr = "week"
        this.$router.push("/dailystatement");
      }
      this.getdashboard();
      this.getindexdashboard();
    },
    Currency() {
      this.getdashboard();
      this.getindexdashboard();
      this.getcurrencyinfo();
    },
  },
  mounted() {
    this.init();
    // 在通过mounted调用即可
  },
  methods: {
    // 余额展示
    Account_balance() {
      this.Account_balanceshow = true;
    },
    // 跳转
    Jump(e) {
      switch (e) {
        case "transaction":
          this.$router.push("/transactionCenter");
          break;
        case "MerchantInfo":
          this.$router.push("/merchantcenter");
          break;
        case "Development":
          window.open(config.DevelopmentDocumentsUrl, '_blank')
          break;
      }
    },
    // 获取信息
    init() {
      user().then((res) => {
        if (res.code == 1) {
          this.mainloading = true;
          this.pid = res.data.userinfo.pid;
          if (res.data.userinfo.currency != "all") {
            this.Currency = res.data.userinfo.currency;
            for (let i = this.options2.length - 1; i >= 0; i--) {
              if (this.options2[i].value != this.Currency) {
                this.options2.splice(i, 1);
              }
            }
          }
          this.mail = res.data.userinfo.username;
          this.money2 = res.data.userinfo.money;
          this.thb_money = res.data.userinfo.thb_money;
          this.usdt_money = res.data.userinfo.usdt_money;
          this.inr_money = res.data.userinfo.inr_money;
          this.wanbi_money = res.data.userinfo.wanbi_money;
          this.gobi_money = res.data.userinfo.gobi_money;
          this.rmb_money = res.data.userinfo.cny_money;
        }
      });
      this.getdashboard();
      this.getindexdashboard();
      this.getcurrencyinfo();
      // let data = {
      //   page: this.page,
      //   limit: this.limit,
      // };
      // indexnotice({}, data).then((res) => {
      //   if (res.code == 1) {
      //     this.listloading = false;
      //     this.newlist = res.data.list;
      //     this.pages = res.data.count;
      //   }
      // });
      info().then((res) => {
        if (res.code == 1) {
          if (res.code == 1) {
            this.nameloading = false;
            this.gameName = res.data.name;
            this.id = res.data.mch_id;
          }
        }
      });
    },
    addtruebtn() {
      this.addtrue.addtrue = false;
      this.addtrue.currency = this.Currency;
      this.init();
    },
    Withdrawaltruebtn() {
      this.Withdrawaltrue.Withdrawaltrue = false;
      this.Withdrawaltrue.currency = this.Currency;
      this.init();
    },
    // 余额提现
    Balancewithdrawal() {
      let data = {
        currency: this.Currency,
      };
      if (
        this.Currency == "CNY" ||
        this.Currency == "MYR" ||
        this.Currency == "THB" ||
        this.Currency == "INR"
      ) {
        bankcard(data).then((res) => {
          if (res.data.count) {
            this.Withdrawaltrue.Withdrawaltrue = true;
            this.Withdrawaltrue.currency = this.Currency;
            this.Withdrawaltrue.type = "1";
          } else {
            this.addtrue.addtrue = true;
            this.addtrue.currency = this.Currency;
            this.addtrue.type = "1";
          }
        });
      } else {
        wallet(data).then((res) => {
          if (res.data.count) {
            this.Withdrawaltrue.Withdrawaltrue = true;
            this.Withdrawaltrue.currency = this.Currency;
            this.Withdrawaltrue.type = "2";
          } else {
            this.addtrue.addtrue = true;
            this.addtrue.currency = this.Currency;
            this.addtrue.type = "2";
          }
        });
      }
      // this.$router.push("/Merchantwithdrawal");
    },
    getNowFormatDate(e) {
      let date = e,
        seperator1 = "-", //格式分隔符
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
      if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

      let currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    getcurrencyinfo() {
      let date = new Date();
      var preDate = new Date(date.getTime() - 24 * 60 * 60 * 1000);
      // 今日
      this.dashboard(
        this.getNowFormatDate(date) +
        " 0:00:00 - " +
        this.getNowFormatDate(date) +
        " 23:59:59",
        1
      );
      // 昨天
      this.dashboard(
        this.getNowFormatDate(preDate) +
        " 0:00:00 - " +
        this.getNowFormatDate(preDate) +
        " 23:59:59",
        2
      );
      // 本月
      this.dashboard(
        this.getNowFormatDate2(preDate) +
        "1 0:00:00 - " +
        this.getNowFormatDate(preDate) +
        " 23:59:59",
        3
      );
    },
    // 获取信息
    dashboard(e, j) {
      let params = {
        timeStr: e,
        currency: this.Currency,
      };
      if (j == 1) {
        params.timeStr = "today";
      } else if (j == 2) {
        params.timeStr = "yesterday";
      } else if (j == 3) {
        params.timeStr = "month";
      }
      indexdashboard("", params).then((res) => {
        this.noSettledMoney = res.data.info.noSettledMoney;
        if (j == 1) {
          this.Todaysamount = res.data.info.totalMoney;
        }
        if (j == 2) {
          this.yesterdayamount = res.data.info.totalMoney;
        }
        if (j == 3) {
          this.monthamount = res.data.info.totalMoney;
        }
        console.log(this.Todaysamount);
      });
    },
    // 切换币种
    changeCurrency(command) {
      this.Currency = command;
    },
    getNowFormatDate2(e) {
      let date = e,
        seperator1 = "-", //格式分隔符
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
      if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

      let currentdate = year + seperator1 + month + seperator1;
      return currentdate;
    },
    // 获取本周
    getFirstDayOfWeek() {
      let data = {};
      let list1 = [];
      let list2 = [];
      let date = new Date();
      var weekday = date.getDay() || 7; //获取星期几,getDay()返回值是 0（周日） 到 6（周六） 之间的一个整数。0||7为7，即weekday的值为1-7
      var oneDayTime = 24 * 60 * 60 * 1000;
      for (let i = 0; i < weekday; i++) {
        let month = new Date(date - oneDayTime * i).getMonth() + 1;
        let strDate = new Date(date - oneDayTime * i).getDate();
        if (month < 10) {
          month = "0" + month;
        }
        if (strDate < 10) {
          strDate = "0" + strDate;
        }
        list1.unshift(month + "-" + strDate);
        list2.unshift(0);
      }
      data = {
        list1,
        list2,
      };
      return data;
    },
    // 获取基础数据
    getindexdashboard() {
      let indexdata = {
        currency: this.Currency,
      };
      indexdashboard({}, indexdata).then((res) => {
        if (res.code == 1) {
          this.dayWith = res.data.info.dayWith;
          this.rechargeTotal = res.data.info.rechargeTotal;
        }
      });
    },
    // 获取折线图数据
    getdashboard() {
      let indexdata = {
        timeStr: this.timeStr,
        currency: this.Currency,
      };
      if (this.Currency == "USDT") {
        indexdata.timeStr = "day";
      }
      indexdashboard({}, indexdata).then((res) => {
        if (res.code == 1) {
          this.money = res.data.info.totalMoney;
          this.num = res.data.info.totalNumber;
          let data = this.getFirstDayOfWeek();
          for (let i = 0; i < res.data.list.X.length; i++) {
            for (let j = 0; j < data.list1.length; j++) {
              if (data.list1[j] == res.data.list.X[i]) {
                data.list2[j] = res.data.list.Y[i];
              }
            }
          }
          this.echartsInit(data);
        }
      });
    },
    // 提现按钮
    Withdrawal() {
      this.Withdrawaldiv = true;
    },
    // 查看详情
    scrrlist_btn(e) {
      this.dialogVisible = true;
      this.notictitle = e.title;
      this.noticcontent = this.changenotic(e.content);
    },
    echartsInit(e) {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      let option = {
        backgroundColor: "#fff",
        tooltip: {
          textStyle: {
            fontSize: 30, // 字体大小
          },
        },
        grid: {
          bottom: 20,
          left: 70,
          right: 70,
          containLabel: true,
          //grid区域是否包含坐标轴的刻度标签
        },
        xAxis: {
          type: "category",
          data: e.list1,
          boundaryGap: false,
          axisLine: { onZero: false },
          axisLabel: {
            textStyle: {
              fontSize: 14,
              fontWeight: 800,
              color: "#000",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              fontSize: 14,
              fontWeight: 800,
              color: "#000",
            },

            formatter: function (value) {
              if (value >= 1000 && value < 10000) {
                value = value / 1000 + "K";
              } else if (value >= 10000) {
                value = value / 10000 + "W";
              }
              return value;
            },
          },
        },
        series: [
          {
            data: e.list2,
            type: "line",
            // smooth: true
          },
        ],
      };
      this.$echarts.init(document.getElementById("main")).setOption(option);
    },
    changelanguage() {
      this.language = this.$units();
    },
    // 转化公告格式
    changenotic(e) {
      e = e.replace(/&amp;/g, "&");
      e = e.replace(/&lt;/g, "<");
      e = e.replace(/&gt;/g, ">");
      e = e.replace(/&nbsp;/g, " ");
      e = e.replace(/&#39;/g, '"');
      e = e.replace(/&quot;/g, '"');
      return e;
    }
  },
};
</script>



<template>
  <div class="main_page">
    <div class="data_box">
      <div class="box_msg"
        style=" background: #fff;color:#444;width:100%;margin-bottom:15px;display:flex;justify-content: space-between">
        <div class="box_title" style="margin:0;text-decoration:underline;display:flex;cursor: pointer;">
          <div @click="Jump('MerchantInfo')">{{ language.MerchantInformation }}</div>

        </div>
        <div style="display: flex;margin-right:20px">
          <div>《{{ language.DevelopmentDocuments }}》——</div>
          <div class="link_text" @click="Jump('Development')" style="margin-right:30px">{{ language.ClickToJump }}
          </div>
          <div class="box_msg_info">{{ language.Merchant_name }}：{{ gameName }}</div>&nbsp;&nbsp;
          <div class="box_msg_info">{{ language.MerchantID }}：{{ id }}</div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <div class="box_msg" style=" background: rgb(228 108 48)">
          <div class="box_title">{{ language.Data_of_Today }}</div>
          <div style="display: flex;">
            <div class="box_msg_title">{{ language.Todaystransactionamount }}:</div>
            <div class="box_msg_info">{{ Todaysamount }} &nbsp;{{ Currency }}</div>
          </div>
          <div style="display: flex;">
            <div class="box_msg_title">{{ language.Withdrawalamounttoday }}:</div>
            <div class="box_msg_info">{{ dayWith }} &nbsp;{{ Currency }}</div>
          </div>

        </div>
        <div class="box_msg" style=" background: rgb(7 148 164)">
          <div class="box_title">{{ language.Businessdata }}</div>
          <div style="display: flex;">
            <div class="box_msg_title">{{ language.Yesterdaystransactionamount }}:</div>
            <div class="box_msg_info">{{ yesterdayamount }}&nbsp;{{ Currency }}</div>
          </div>

          <div style="display: flex;">
            <div class="box_msg_title">{{ language.Transactionamountthisweek }}:</div>
            <div class="box_msg_info">{{ money }}&nbsp;{{ Currency }}</div>
          </div>

          <div style="display: flex;">
            <div class="box_msg_title">{{ language.Transactionamountforthismonth }}:</div>
            <div class="box_msg_info">{{ monthamount }}&nbsp;{{ Currency }}</div>
          </div>

          <div style="display: flex;">
            <div class="box_msg_title">{{ language.Accumulatedtransactionamount }}:</div>
            <div class="box_msg_info">{{ rechargeTotal }}&nbsp;{{ Currency }}</div>
          </div>

        </div>
        <div class="box_msg" style="background: rgb(55 182 60)">

          <div class="box_title">{{ language.Amountinformation }}</div>
          <div style="display: flex;">
            <div class="box_msg_title">{{ language.Currency }}:</div>
            <div class="box_msg_info">
              {{ language.IndianRupee }}&nbsp;{{ Currency }}
              <!-- <el-dropdown @command="changeCurrency">
                <span class="el-dropdown-link" style="color: #fff">{{ Currency }} <i
                    class="el-icon-arrow-down el-icon--right"></i></span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item in options2" :key="item.value" :command="item.value">{{ item.value
                    }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
            </div>
          </div>
          <div style="display: flex;">
            <div class="box_msg_title">{{ language.Account_balance }}:</div>
            <div class="box_msg_info" style="justify-content: space-between">
              <div v-if="Currency == 'MYR'">{{ money2 }}&nbsp;{{ Currency }}</div>
              <div v-if="Currency == 'THB'">{{ thb_money }}&nbsp;{{ Currency }}</div>
              <div v-if="Currency == 'USDT'">{{ usdt_money }}&nbsp;{{ Currency }}</div>
              <div v-if="Currency == 'INR'">{{ inr_money }}&nbsp;{{ Currency }}</div>
              <div v-if="Currency == 'WanBi'">{{ wanbi_money }}&nbsp;{{ Currency }}</div>
              <div v-if="Currency == 'GoBi'">{{ gobi_money }}&nbsp;{{ Currency }}</div>
              <div v-if="Currency == 'CNY'">{{ rmb_money }}&nbsp;{{ Currency }}</div>
              <!-- <div @click="Account_balance()" style="cursor: pointer; padding-left: 20px; font-size: 14px">{{
                language.balanceinquiry }}</div> -->
            </div>
          </div>
          <div style="display: flex;">

            <div class="box_msg_title">{{ language.Pendingsettlementamount }}:</div>
            <div class="box_msg_info">{{ noSettledMoney || "0.00" }}&nbsp;{{ Currency }}</div>
          </div>
        </div>
        <!-- <div class="box_msg" style="width: 20%; background: #2196f3">
          <div class="box_msg_title">{{ language.MerchantInformation }}:</div>
          <div class="box_msg_info">{{ language.Merchant_name }}：{{ gameName }}</div>
          <div class="box_msg_info" style="margin-top: 20px">{{ language.MerchantID }}：{{ id }}</div>
        </div> -->
      </div>
      <div style="display: flex; flex: 1; height: 100%; margin-top: 20px">
        <div
          style="border-right: 1px solid #efefef; flex: 1; display: flex; flex-direction: column; padding: 20px; box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.35); background: #fff; border-radius: 10px;">
          <div class="Jump">
            <div class="link_text" @click="Jump('transaction')">{{
              language.transaction_detail_enquiry }}
            </div>
          </div>
          <div style="display: flex; flex-direction: column; align-items: flex-end">
            <div style="display: flex">
              <div>{{ language.Transaction_amount_in_the_last_week }}</div>
              <div style="width: 68px; text-align: right">{{ money }}</div>
            </div>
            <div style="display: flex">
              <div>{{ language.Number_of_transactions_in_the_last_week }}</div>
              <div style="width: 68px; text-align: right">{{ num }}</div>
            </div>
          </div>
          <div id="main" style="flex: 1" v-loading="mainloading"></div>
        </div>
      </div>
    </div>
    <el-dialog :title="language.Account_balance" :visible.sync="Account_balanceshow" width="500px">
      <div class="money_big_box">
        <div>
          <div><span class="money_box">INR{{ language.balance }}:</span><span>{{ inr_money }}</span></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="Account_balanceshow = false">{{ language.determine }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="language.Withdrawal" :visible.sync="Withdrawaldiv" width="30%">
      <div>{{ notictitle }}</div>
      <div v-html="noticcontent"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{ language.determine }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="language.Notice" :visible.sync="dialogVisible" width="30%">
      <div>{{ notictitle }}</div>
      <div v-html="noticcontent"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{ language.determine }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<style scoped>
.main_page {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
}

.data_box {
  width: 100%;
  flex: 1;
  height: calc(100vh - 120px);
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.box_msg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
  color: #fff;
  border-radius: 8px;
  width: 32%;
  box-sizing: border-box;
}

.box_title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.box_msg .box_msg_title {
  margin-bottom: 5px;
}


.box_msg_info {
  margin-left: 10px;
  display: flex;
}

.money_big_box {
  font-size: 20px;
}

.money_big_box>div {
  border: 1px solid #efefef;
  border-bottom: none;
}

.money_big_box>div:last-child {
  border-bottom: 1px solid #efefef;
}

.money_box {
  display: inline-block;
}

.link_text {
  text-decoration: underline;
  color: #FD6303;
  cursor: pointer
}
</style>
